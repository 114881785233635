.page-submenu {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0 0 40px 0;
  padding: 0;
}
.page-submenu li {
  margin-right: 60px;
}
.page-submenu li:last-child {
  margin-right: 0;
}
.page-submenu li a {
  font-size: 17px;
  line-height: 26px;
  color: $base-font-color;
}
.page-submenu li a:hover {
  color: $danger-color;
}
.page-submenu li.active a {
  color: $danger-color;
  font-weight: 600;
}
.period-title {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 30px;
}

.record-card {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  height: 100%;
}
.record-card-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-top: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.record-card img {
  width: 71px;
  height: 71px;
  border-radius: 50%;
  overflow: hidden;
}
.record-card-time {
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  color: $base-gray-color;
}
.record-card .btn-wake-up {
  width: 100%;
  margin-top: 20px;
  padding: 10px 10px;
  font-size: 15px;
  line-height: 22px;
}
.period-section {
  margin-bottom: 30px;
}

@include media-breakpoint-down(lg) {
  .record-card-row {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .record-card-time {
    font-size: 13px;
    line-height: 16px;
  }
  .record-card-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }
  .page-submenu {
    margin-bottom: 30px;
    overflow: auto;
  }
  .page-submenu li {
    margin-right: 25px;
  }
  .page-submenu li a {
    font-size: 15px;
    line-height: 20px;
  }
  .record-card {
    width: 231px;
    padding: 30px 15px;
  }
  .period-title {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}