.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}
.sort-block {
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.sort-block li {
  margin-right: 30px;
  //color: $base-gray-color;
  //font-size: 13px;
  //line-height: 16px;
}
.sort-block li a {
  color: $base-gray-color;
  font-size: 13px;
  line-height: 16px;
}
//.sort-block li.active {
//  font-weight: 600;
//  color: $base-font-color;
//}
.sort-block li.active a {
  font-weight: 600;
  color: $base-font-color;
}
//.sort-block li:hover {
//  cursor: pointer;
//  color: $base-font-color;
//}
.sort-block li a:hover {
  cursor: pointer;
  color: $base-font-color;
}
.sort-block li:last-child {
  margin-right: 0;
}
.sort-block-mob {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.filter-calendar {
  color: $base-gray-color;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  position: relative;
}
.filter-calendar:hover {
  color: $base-link-color;
}
.filter-calendar-selected {
  color: $base-link-color;
  font-weight: 600;
}

.statistic-card {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 35px 15px;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}
.statistic-card span {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 10px;
  display: block;
  color: $danger-color;
}
.wake-up-calendar {
  font-family: Inter;
}
.wake-up-calendar.daterangepicker {
  background: #FFFFFF;
  border: 0.531418px solid #D9DCEB;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
  border-radius: 10px;
}

.wake-up-calendar .drp-calendar {
  padding: 20px !important;
  max-width: 100% !important;
}
.wake-up-calendar .drp-calendar .calendar-table {
  padding: 0 !important;
}
.wake-up-calendar.daterangepicker th.month {
  font-size: 15px;
  line-height: 20px;
  padding: 5px 0;
}

.wake-up-calendar.daterangepicker .calendar-table table thead tr:last-child th {
  padding: 24px 0 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  height: auto;
  border: 0;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.off {
  color: #D9DCEB;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td {
  font-size: 15px;
  line-height: 18px;
  color: #999daf;
  min-height: 33px;
  width: 35px;
  border: 0;
  border-radius: 0;
  min-width: 35px;
  height: 33px;
  position: relative;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.active:before {
  content: "";
  background: $base-link-color !important;
  position: absolute;
  width: 23px;
  height: 23px;
  top: 50%;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.active {
  background-color: transparent;
  color: #fff;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td span {
  position: relative;
  z-index: 10;
}

.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.available:hover {
  background-color: transparent;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.available:hover:before {
  content: "";
  background: #e9e9e9;
  position: absolute;
  width: 23px;
  height: 23px;
  top: 50%;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.in-range:after {
  content: '';
  background: #e9e9e9;
  position: absolute;
  width: 100%;
  height: 23px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.active.start-date:after {
  content: '';
  background: #e9e9e9;
  position: absolute;
  width: 50%;
  height: 23px;
  right: 0;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr .in-range:hover:after {
  content: '';
  background: #e9e9e9;
  position: absolute;
  width: 50%;
  height: 23px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr .in-range-row:hover:after {
  content: '';
  background: #e9e9e9;
  position: absolute;
  width: 100% !important;
  height: 23px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr .in-range.end-date:after {
  content: '';
  background: #e9e9e9;
  position: absolute;
  width: 50% !important;
  height: 23px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.active.start-date.end-date:after {
  content: none;
}
.wake-up-calendar.daterangepicker .calendar-table table tbody tr td.in-range {
  background-color: transparent;
}


.tooltip-sort .tooltipster-box {
  padding: 15px 20px;
}
.tooltip-sort .tooltipster-content ul li {
  margin-bottom: 10px;
}
.tooltip-sort .tooltipster-content ul li a {
  font-size: 15px;
  line-height: 20px;
  color: $base-font-color;
  margin-right: 0;
}
.tooltip-sort .tooltipster-content ul li:last-child {
  margin-bottom: 0px;
}

@include media-breakpoint-up(lg) {
  .sort-block {
    display: flex !important;
    opacity: 1 !important;
    position: relative !important;
    min-width: auto !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
@include media-breakpoint-down(lg) {
  .sort-block ul li:last-child {
    margin-bottom: 0px;
  }
  .sort-block ul li {
    margin-bottom: 10px;
  }
  .sort-block ul li a {
    font-size: 15px;
    line-height: 20px;
    color: $base-font-color;
    margin-right: 0;
  }
  .sort-block {
    padding: 15px 20px;
  }
  .filter-row {
    margin-bottom: 31px;
  }
}