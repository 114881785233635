.therapist-item {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  height: 100%;
}
.therapist-item img {
  margin: 0 auto 25px;
  border-radius: 50%;
  overflow: hidden;
  width: 166px;
  height: 166px;
  display: block;
}
.therapist-name {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: $base-font-color;
  min-height: 53px;
  display: inline-block;
}
.therapist-experience {
  margin-top: 25px;
}
.therapist-count {
  color: $base-gray-color;
  margin-top: 10px;
}
.therapist-skills {
  margin: 20px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.therapist-skills li {
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  padding: 5px 15px;
  border-radius: 50px;
  background-color: $base-link-color;
  margin: 10px 5px 0;
}
.therapist-skills li.therapist-skill-more {
  border: 1px solid $base-link-color;
  background-color: #fff;
}
.therapist-skill-more a {
  color: $base-link-color;
}

@include media-breakpoint-down(lg) {

  .therapist-skills {
    margin-top: 10px;
  }
  .therapist-skills li {
    padding: 5px 10px;
    font-size: 11px;
    line-height: 14px;
  }
  .therapist-count {
    font-size: 15px;
    line-height: 20px;
  }
  .therapist-experience {
    margin-top: 15px;
    font-size: 15px;
    line-height: 20px;
  }
  .therapist-name {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    min-height: auto;
  }

  .therapist-item img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(md) {

  .therapist-item img {
    width: 80px;
    height: 80px;
  }
}