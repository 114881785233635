.chat-body {
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-body-footer {
  position: relative;
  border-top: 1px solid #D9DCEB;
}

.chat-textarea-icon {
  position: absolute;
  top: 18px;
  left: 27px;
  cursor: pointer;
}

#chat-send-btn {
  position: absolute;
  right: 26px;
  bottom: 5px;
  cursor: pointer;
  padding: 10px;
}

#chat-message {
  height: 50px;
  border: 0;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  padding: 15px 60px;
  display: block;
  resize: none;
  overflow: hidden;
}

.chat-body-content {
  padding: 0 30px 30px;
  display: flex;
  //flex-direction: column-reverse;
  //height: 500px;
  overflow: auto;
  flex-grow: 1;
}

.chat-date {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-text-info {
  margin-top: 20px;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}

.chat-date span {
  font-size: 13px;
  line-height: 16px;
  padding: 5px 10px;
  color: $base-gray-color;
  background: #F8F7FA;
  border-radius: 50px;
  text-transform: lowercase;
}

.chat-message {
  padding: 10px 15px;
  background: rgba(105, 123, 214, 0.1);
  border-radius: 10px;
  margin-top: 20px;
  width: fit-content;
  word-break: break-word;
}

.chat-message-meta {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.chat-message-meta span {
  color: $base-gray-color;
  font-size: 11px;
  line-height: 14px;
  margin-left: 5px;
}

.current-message-user {
  background: #D2D7F3;
}

.message-right {
  align-self: flex-end;
}

.chat-body-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-message-status svg path {
  fill: #5A5A5A;
}

.chat-message-status {
  display: flex;
  align-items: center;
}

.chat-message-status svg {
  margin-left: -7px;
}

.chat-message-status svg:first-child {
  margin-left: 0;
}

.chat-message-status.read svg path {
  fill: $base-link-color;
}

.chat-user-block {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.chat-user-img {
  width: 41px;
  height: 41px;
  position: relative;
  margin-right: 24px;
  flex-shrink: 0;
}

.chat-user-img img {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  overflow: hidden;
}

.chat-user-img span {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: $danger-color;
  bottom: 0;
  right: 0;
}

.chat-user-name {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
}

.chat-body-header {
  padding: 30px 30px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#chat-video {
  margin-left: 30px;
}

.chat-actions {
  display: flex;
  align-items: center;
}

.chat-header-actions-item:hover {
  cursor: pointer;
}

.chat-header-actions-item:hover svg path {
  fill: $base-link-focus-color;
}

.chat-header-actions svg:hover {
  cursor: pointer;
}

.chat-header-actions svg:hover path {
  fill: $base-link-focus-color;
}

.chat-header-actions-item span {
  display: none;
}

.chat-header-actions-mobile {
  display: none;
}

.custom-scrollbars * {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #999 #eee;
}

.custom-scrollbars ::-webkit-scrollbar {
  height: .4rem;
  width: .4rem;
}

.custom-scrollbars ::-webkit-scrollbar-track {
  background: #eee
}

.custom-scrollbars ::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: .4rem
}

.call-popup {
  padding: 80px;
  border-radius: 10px;
  text-align: center;
  background-color: $base-link-color;
  min-width: 546px;
}

.call-popup-img {
  width: 157px;
  height: 157px;
  border-radius: 100%;
  margin: 0 auto;
}

.call-popup-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #fff;
  margin-top: 30px;
}

.call-popup-action {
  margin-top: 10px;
  color: #D9DCEB;
}

.btn-call-popup {
  font-size: 17px;
  line-height: 26px;
  background: #fff;
  height: 56px;
  margin: 50px 0 0;
  width: 100%;
}

.btn-call-popup:hover {
  box-shadow: 0px 10px 33px rgba(112, 127, 209, 0.25);
}

.call-video-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}

.call-video-btns .btn-call-popup {
  margin-top: 0;
  margin-left: 24px;
}

.btn-call-popup-close {
  font-size: 17px;
  line-height: 26px;
  background: transparent;
  color: #fff;
  height: 56px;
  border: 1px solid #fff;
  width: 100%;
}

.btn-call-popup-close:hover {
  color: #fff;
  box-shadow: 0px 10px 33px rgba(112, 127, 209, 0.25);
}

.call-popup-stream {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(49,49,49,.3);
  padding: 50px;
}
.call-popup-stream.small-mode {
  background-color: transparent;
  //height: auto;
}

#call-video-stream {
  z-index: 99999;
  &.open-popup-stream {
    opacity: 1;
    display: flex;
    .stream-content {
      opacity: 1;
    }
  }
  transition: all 0.3s;
  display: none;
  opacity: 0;

  .stream-content {
    position: relative;
    opacity: 0;
    height: 100%;
    max-height: 600px;
    //transition: all 0.3s;

    video.main-stream {
      width: 100%;
      height: 100%;
      background: grey;
      border-radius: 10px;
    }
  }

  .stream-companion {
    top: 30px;
    left: 30px;
    width: 230px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
    //background-color: red;
    position: absolute;
    transition: all 0.3s;
    opacity: 1;
  }

  .stream-mode {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    border: 0;
    background: rgba(49, 49, 49, 0.6);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    flex-direction: 0;

    .stream-full {
      display: none;
    }

  }


  .stream-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    transition: all 0.3s;

    button {
      border: 0;
      border-radius: 50%;
      overflow: hidden;
      background: rgba(49, 49, 49, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      width: 40px;
      height: 40px;
      margin: 0 25px;
      flex-shrink: 0;
      &:hover{
        background-color: white;
        path{
          fill: rgba(49, 49, 49, 0.6);
        }
      }
      svg {
        transition: all 0.3s;
      }
    }
  }


  &.small-mode {
    .stream-companion {
      opacity: 0;
    }
    //padding: 0;
    //left: 50px;
    //bottom: 50px;
    //width: 300px;
    //height: auto;
    //top: auto;

    .stream-content {
      //height: auto;
      //position: fixed;
      //bottom: 45px;
      //left: 78px;
      //width: 340px;
    }

    .stream-mode {
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;
    }
    .stream-full {
      display: block;
    }
    .stream-small {
      display: none;
    }

    .stream-controls {
      display: none;

      bottom: 10px;

      button {
        width: 25px;
        height: 25px;
        margin: 0 5px;

        svg {
          transform: scale(0.8);
        }
      }
    }
  }


}

.block-scroll {
  overflow: hidden;
}

.chat-container {
  display: flex;
  height: 100%;
}

.chat-lists {
  background: #FFFFFF;
  border-radius: 10px;
  margin-right: 24px;
  width: 33%;
  overflow: auto;
  //height: 647px;

  height: 100%;

  &.chat-lists-hide {
    opacity: 1 !important;
  }
}
.chat-item {
  padding: 15px 10px;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}
.chat-item * {
  //transition: all 0.3s;
}
.chat-item:hover {
  cursor: pointer;
  background-color: $base-link-color;
  .chat-user-typing div {
    background-color: #fff;
  }
  .chat-item-unread {
    color: #fff;
  }
}
.chat-item:hover * {
  color: #fff !important;
}
.chat-item:hover svg path {
  fill: #f7f7f7 !important;
}
.chat-item:hover .read svg path {
  fill: $danger-color !important;
}

.chat-item.active {

  background-color: $base-link-color;

  * {
    color: #fff !important;
  }

  svg path {
    fill: #f7f7f7 !important;
  }

  .chat-item-unread {
    color: $base-link-color;
  }

  .read svg path {
    fill: $danger-color !important;
  }
}

.chat-item-img {
  width: 41px;
  height: 41px;
  position: relative;
  margin: 5px 15px 6px 0;
  flex-shrink: 0;
}

.chat-item-img img {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  overflow: hidden;
}

.chat-item-img span {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: $danger-color;
  bottom: 0;
  right: 0;
}
.chat-item-data {
  flex-grow: 1;
  min-width: 0;
}
.chat-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat-item-name > span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chat-item-meta {
  font-size: 13px;
  line-height: 16px;
  color: $base-gray-color;
  margin-left: 18px;
  display: flex;
  align-items: center;
}
.chat-item-meta-status svg path {
  fill: $base-gray-color;
}
.chat-item-meta-status.read svg path {
  fill: $danger-color;
}
.chat-item-meta-status {
  margin-right: 10px;
  display: flex;
  align-items: center;
}


.chat-item-meta-status svg {
  margin-left: -7px;
}

.chat-item-meta-status svg:first-child {
  margin-left: 0;
}

.chat-item-message {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 24px;
}

.chat-item-message span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  line-height: 24px;
  color: #5a5a5a;
}

.chat-item-unread {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  background: #D9DCEB;
  border-radius: 50%;
  font-size: 13px;
  line-height: 16px;
  margin-left: 15px;
}

#chat-back {
  cursor: pointer;
  flex-shrink: 0;
  padding-right: 15px;
  height: 100%;
  width: 32px;
}

.chat-card {
  display: flex;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
  border-radius: 10px;
  padding: 20px 20px 40px 20px;
  margin-top: 20px;
  width: 100%;
  max-width: 515px;
  flex-wrap: wrap;
  position: relative;
}

.chat-card-body {
  width: 100%;
  margin-left: 75px;
  margin-top: -25px;
}

.chat-card .chat-message-meta {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.chat-card-name {
  font-weight: 600;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 75px);
}

.chat-card-text {
  margin-top: 10px;
  font-size: 15px;
  line-height: 20px;
}
.chat-card-text span {
  color: $base-gray-color;
}

.chat-card-img {
  width: 51px;
  height: 51px;
  position: relative;
  margin-right: 24px;
  flex-shrink: 0;
}

.chat-card-img img {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  overflow: hidden;
}

.chat-card-img span {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: $danger-color;
  bottom: 0;
  right: 0;
}
.chat-card-price {
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
}

.chat-card-btns {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.chat-card-btns button:first-child {
  margin-right: 20px;
}
.chat-card-btns button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  line-height: 20px;
  margin-top: 0;
  padding: 5px 10px;
}


.chat-group {
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  padding: 20px;
}

.chat-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.chat-therapists-finded {
  overflow-y: auto;
  max-height: 144px;
}

#chat-therapist-choice {
  margin-bottom: 30px;
  display: flex;
  align-items: flex-end;
}
.therapist-choice-date, .therapist-choice-time {
  margin-right: 22px;
}
.therapist-choice-date, .therapist-choice-time, .therapist-choice-price {
  width: 20%;
}
.therapist-choice-field  span {
  font-size: 15px;
  line-height: 20px;
  display: block;
  margin-bottom: 10px;
  color: $base-gray-color;
}
.therapist-choice-field input {
  background: #FFFFFF;
  border: 1px solid #D9DCEB;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  padding: 0 15px;
}
.therapist-choice-group {
  display: flex;
  align-items: center;
}
.therapist-choice-group input:first-child {
  margin-right: 8px;
}
.therapist-choice-group input:last-child {
  margin-left: 8px;
}

#chat-therapist-choice .btn-wake-up {
  height: 40px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-left: auto;
}


.chat-card-status {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;


  button {
    font-size: 15px;
    line-height: 20px;
    padding: 10px 28px;
    margin-right: 20px;
    width: auto;
    margin-top: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}
.chat-card-status svg {
  margin-right: 10px;
}
.chat-card-status.success {
  color: #20B22F;
}
.chat-card-status.error {
  color: #FD4B4B;
}

.chat-therapist-records {
  margin-top: 20px;
  margin-bottom: 20px;
}

.chat-therapist-title {
  font-size: 15px;
  line-height: 20px;
  color: $base-gray-color;
}
.chat-therapist-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 20px;
  padding: 13px 20px;
  background: #FFFFFF;
  border: 1px solid #D9DCEB;
  border-radius: 10px;
  transition: all 0.3s;


  &:hover {
    background-color: $base-link-color;
    border-color: $base-link-color;
    color: #fff;
    cursor: pointer;

    .chat-therapist-times span:hover {
      border-color: #fff;
      background-color: #fff;
      color: $base-font-color;
    }
  }

  &.active {
    background-color: $base-link-color;
    border-color: $base-link-color;
    color: #fff;

    .chat-therapist-times span {
      border-color: #fff;
    }
  }
}
.chat-therapist-name {
  display: flex;
  align-items: center;
}
.chat-therapist-name img {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}
.chat-therapist-times span {
  padding: 5px 10px;
  border: 1px solid #C8CCE2;
  border-radius: 50px;
  margin-left: 10px;
  transition: all 0.3s;

  &.active {
    background-color: #fff;
    color: $base-font-color;
  }
}
.chat-therapist-times span:first-child {
  margin-left: 0;
}
.chat-therapist-times {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.therapist-choice {
  width: 100%;
  margin-bottom: 20px;
  display: none;
}
.chat-search-back {
  display: none;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 30px;
  align-items: center;
}
.chat-search-back  svg {
  margin-right: 10px;
}

.chat-user-typing {
  line-height: 20px;
}
.chat-user-typing div {
  width: 5px;
  height: 5px;
  animation-fill-mode: both;
  background-color: $base-link-color;
  border-radius: 100%;
  margin: 2px;
  display: inline-block;
}
.active .chat-user-typing div {
  background-color: #fff;
}
.chat-user-typing>div:nth-child(1) {
  animation: scale .75s -.24s infinite cubic-bezier(.2,.68,.18,1.08);
}
.chat-user-typing>div:nth-child(2) {
  animation: scale .75s -.12s infinite cubic-bezier(.2,.68,.18,1.08);
}
.chat-user-typing>div:nth-child(3) {
  animation: scale .75s 0s infinite cubic-bezier(.2,.68,.18,1.08);
}

@-webkit-keyframes scale {
  0%,80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  45% {
    -webkit-transform: scale(.1);
    transform: scale(.1);
    opacity: .7
  }
}

@keyframes scale {
  0%,80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  45% {
    -webkit-transform: scale(.1);
    transform: scale(.1);
    opacity: .7
  }
}



.popup-chat .fancybox__slide {
  padding: 40px;
}
//#popup-chat .is-close {
//  top: 0px;
//}
//#popup-chat .is-close path {
//  stroke: $base-font-color;
//}
#popup-chat {
  width: 100%;
  height: 100%;
  background: #f8f7fa;
}

#popup-chat :focus {
  outline: none;
}

.microphone-status .off {
  display: none;
}
.microphone-status.off .off {
  display: flex;
}
.microphone-status.off .on {
  display: none;
}


.video-status .off {
  display: none;
}
.video-status.off .off {
  display: flex;
}
.video-status.off .on {
  display: none;
}


.sound-status .off {
  display: none;
}
.sound-status.off .off {
  display: flex;
}
.sound-status.off .on {
  display: none;
}

@include media-breakpoint-up(lg) {
  .chat-actions {
    display: flex !important;
    position: relative !important;
    opacity: 1 !important;
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    min-width: auto !important;
  }
}
@include media-breakpoint-down(lg) {
  .popup-chat .fancybox__content>.carousel__button.is-close {
    top: -3px;
  }
  .popup-chat .fancybox__content>.carousel__button.is-close svg path {
    stroke: $base-gray-color;
  }
  .popup-chat .fancybox__slide {
    padding: 0px;
  }
  .popup-chat .fancybox__content {
    padding: 30px;
  }
  .chat-search-back {
    display: flex;
  }
  .therapist-choice.therapist-choice-hide,
  .chat-body.therapist-choice-hide {
    display: none;
  }
  .chat-therapist-search {
    display: none;
    opacity: 0;
    height: 100%;
    flex-direction: column;
  }
  .chat-therapist-times span {
    font-size: 13px;
    line-height: 16px;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 0;
  }
  .chat-therapists-finded {
    max-height: none;
    overflow: auto;
  }

  .chat-therapist-times span:last-child {
    margin-right: 0;
  }
  .chat-therapist-times {
    margin-left: 44px;
  }
  .chat-therapist-name {
    width: 100%;
  }

  .chat-therapist-item {
    flex-wrap: wrap;
    padding: 15px;
  }
  .chat-therapist-records {
    margin-top: 0;
    flex-grow: 1;
    overflow: auto;
    margin-bottom: 0;
  }
  #chat-therapist-choice .btn-wake-up {
    //display: none;
  }
  #chat-therapist-choice {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .therapist-choice-date {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .therapist-choice-time, .therapist-choice-price {
    flex-grow: 1;
  }

  .therapist-choice {
    display: block;
  }

  .chat-card-btns button {
    font-size: 13px;
  }
  .chat-card-btns {
    margin-top: 15px;
  }
  .chat-card-btns button:first-child {
    margin-right: 10px;
  }
  .chat-card-name {
    font-size: 15px;
    line-height: 20px;
  }
  .chat-card {
    margin-top: 15px;
    align-items: center;
  }
  .chat-card-body {
    margin-top: 0px;
    margin-left: 0;
    font-size: 15px;
    line-height: 20px;
  }
  .chat-card-text span {
    display: block;
    margin-bottom: 5px;
  }
  .chat-card-text {
    font-size: 13px;
    line-height: 16px;
  }
  .chat-card-img {
    margin-right: 12px;
  }
  .chat-lists {
    margin: 0;
    //max-height: 400px;
    height: auto;
    width: 100%;

    &.chat-lists-hide {
      display: none;
    }
  }
  .full-chat .chat-block {
    display: none;
    opacity: 0;
  }

  .chat-item-message span {
    font-size: 13px;
    line-height: 16px;
  }
  .chat-item-img {
    margin: 0 13px 0;
  }
  .chat-item-name > span {
    font-size: 13px;
    line-height: 16px;
  }

  #call-video-stream {
    padding: 0;


    .stream-content {
      max-height: none;

      video.main-stream {
        border-radius: 0;
      }

      video.stream-companion {
        width: 78px;
        height: 138px;
        top: 20px;
        left: 20px;
      }

      .stream-mode {
        top: 10px;
        right: 10px;
      }

      .stream-controls {
        bottom: 10px;
        button {
          margin: 0 4px;
        }
      }
    }

    &.small-mode {
      video.main-stream {
        border-radius: 10px;
      }
    }
  }
  .call-video-btns {
    margin-top: 40px;
    flex-direction: column;
  }
  .call-video-btns .btn-call-popup {
    margin-top: 20px;
    margin-left: 0px;
  }
  .call-popup-class .fancybox__slide {
    padding: 0 !important;
  }
  .call-popup {
    min-width: auto;
    width: 100%;
    padding: 30px;
    height: 100%;
    align-items: center;
    display: flex;
    border-radius: 0;
    justify-content: center;
  }
  #chat-actions .chat-header-actions-item:last-child {
    margin-top: 15px;
  }
  .chat-header-actions-item span {
    display: block;
    color: $base-font-color;
    font-size: 15px;
    line-height: 20px;
  }
  .chat-header-actions-item {
    display: flex;
    align-items: center;
  }
  .chat-header-actions-item svg {
    margin-right: 5px;
    width: 22px;
    height: 22px;
    margin-left: 0 !important;
  }
  .chat-actions {
    display: none;
  }
  .chat-header-actions-mobile {
    display: block;
  }
  #chat-send-btn {
    right: 10px;
  }
  .chat-textarea-icon {
    left: 17px;
  }
  #chat-message {
    padding: 15px 50px;
  }
  .chat-body-content {
    padding: 0 12px 0px;
    margin-bottom: 15px;
  }
  .chat-message {
    font-size: 13px;
    line-height: 16px;
    margin-top: 15px;
  }
  .chat-date {
    margin-top: 15px;
  }
  .chat-date span {
    font-size: 11px;
    line-height: 14px;
  }

  .chat-text-info {
    margin-top: 15px;
    color: #5A5A5A;
  }

  .chat-body-header {
    padding: 20px 12px;
  }
  .chat-user-img {
    margin-right: 10px;
  }
  .chat-user-name {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
  }
}