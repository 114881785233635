body {
  background: #F8F7FA;
  font-family: Inter;
  color: $base-font-color;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
a {
  text-decoration: none;
  color: $base-link-color;
}
a:hover {
  color: $base-link-focus-color;
}
a:active {
  color: $base-link-active-color;
}

* {
  outline: none;
}
.btn {
  transition: all .3s;
}
.btn svg path {
  transition: all .3s;
}
.btn svg {
  transition: all .3s;
}
.btn:focus {
  box-shadow: none !important;
}

.btn-wake-up {
  font-weight: 600;
  border: 0;
  font-size: 17px;
  color: #fff;
  background-color: $base-link-color;
  border-radius: 5px;
  padding: 14px 50px;
  position: relative;
  //box-shadow: inset 0px 0px 33px #fff;
}
.btn-wake-up:active {
  background: $base-link-focus-color;
}
.btn-wake-up:hover {
  box-shadow: 0px 10px 33px rgba(112, 127, 209, 0.25);
  color: #fff;
}
.btn-danger-wake-up {
  font-weight: 600;
  border: 0;
  font-size: 17px;
  color: #fff;
  background-color: $danger-color;
  border-radius: 5px;
  padding: 14px 50px;
  position: relative;
  //box-shadow: inset 0px 0px 33px #fff;
}
.btn-danger-wake-up:active {
  background: $danger-focus-color;
}
.btn-danger-wake-up:hover {
  box-shadow: 0px 10px 33px rgba(112, 127, 209, 0.25);
  color: #fff;
}

.btn-wake-up-record {
  background-color: #fff;
  color: $base-link-color;
  border: 1px solid #D9DCEB;
  border-radius: 5px;
  width: 100%;
  padding: 10px 10px;
  font-size: 15px;
  line-height: 22px;
  margin-top: 10px;
}
.btn-wake-up-record:hover {
  border-color: $base-link-color;
  color: $base-link-color;
}
.btn-wake-up-record:active {
  border-color: $base-link-active-color;
  color: $base-link-active-color;

}


.btn-wake-up-white {
  font-size: 17px;
  line-height: 26px;
  color: $base-link-color;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  padding: 14px 10px;
  transition: all 0.3s;
}

.btn-wake-up-white svg {
  margin-right: 15px;
}
.btn-wake-up-white svg path {
  transition: all 0.3s;
}

.btn-wake-up-white:hover {
  color: $base-link-color;
  box-shadow: 0px 10px 33px rgba(112, 127, 209, 0.25);
}
.btn-wake-up-white:hover svg path {
}
.btn-wake-up-white:active {
  box-shadow: none;
  color: #49569D;
}
.btn-wake-up-white:active svg path {
  fill: #49569D;
}

.text-wake-up-danger {
  color: $danger-color;
}

.arrow-effects path {
  fill: $danger-color;
}
.arrow-effects:active path {
  fill: $danger-focus-color;
}

.tooltipster-sidetip .tooltipster-content {
  padding: 0 !important;
}


.form-control {
  border: 1px solid #D9DCEB;
  border-radius: 5px;
  padding: 0 15px;
  height: 50px;
}
.form-control:focus {
  box-shadow: none !important;
}
.error-field-text {
  color: $error-color;
  font-size: 14px;
  line-height: 22px;
  margin-top: 8px;
}
.not-valid {
  border-color: $error-color;
}
select.not-valid + .select2-container .select2-selection {
  border-color: $error-color;
}
h2 {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
}
h1 {
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  margin: 0;
}


.page-title {
  margin-bottom: 37px;
}
.main {
  padding-bottom: 50px;
  flex-grow: 1;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.7);
  z-index: 10;
}

.loader {
  width: 40px;
  height: 40px;
  border: 3px solid transparent;
  border-radius: 50%;
  position: relative;
  margin: 10px auto;
}

.loader::before {
  content: '';
  border: 3px solid transparentize($base-link-color, 0.5);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  top: -7px;
  left: -7px;
  animation: loader-scale 1s ease-out infinite;
  animation-delay: .5s;
  opacity: 0;
}

.loader::after {
  content: '';
  border: 3px solid $base-link-color;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -3px;
  left: -3px;
  animation: loader-scale 1s ease-out infinite;
  animation-delay: 0s;
}

@keyframes loader-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@include media-breakpoint-down(lg) {
  .breadcrumbs-block {
    padding: 20px 0;
    font-size: 13px;
    line-height: 16px;
  }
  .btn-wake-up {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  h2 {
    font-size: 21px;
    line-height: 26px;
  }

  h1 {
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
  }
}