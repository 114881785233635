.record-card-time span {
  color: $base-gray-color;
}

.admin-stat-card .admin-stat-money {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  color: $danger-color;
  margin-top: 40px;
  margin-bottom: 18px;
}
.admin-stat-card .record-card-name {
  -o-text-overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  height: 52px;
}

.admin-therapist-action .btn-wake-up-record {
  margin: 0;
}

.admin-therapist-action .btn-wake-up {
  padding: 10px;
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}

.filter-search {
  position: relative;
}
.filter-search svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
}

.filter-search input {
  padding-left: 45px;
  width: 100%;
}


.sort-element {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  color: $base-link-color;
  cursor: pointer;
}
.sort-element:hover {
  color: $base-link-focus-color;
}
.sort-element svg {
  margin-right: 10px;
}

.base-record-card {
  padding: 30px 10px;
}
.base-record-card img {
  width: 130px !important;
  height: 130px !important;
}
.base-record-card .record-card-name {
  -o-text-overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  height: 48px;
  color: $base-font-color;
}

.base-record-card .record-card-name:hover {
  color: $base-link-focus-color;
}

.base-card-exp {
  margin-top: 20px;
}
.base-card-count {
  margin-top: 10px;
  color: $base-gray-color;
}

.history-chat {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}
.history-chat-img {
  flex-shrink: 0;
  position: relative;
  width: 54px;
  height: 54px;
  margin-right: 20px;
}
.history-chat-img img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  overflow: hidden;
}
.history-chat-img span {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: $danger-color;
  bottom: 0;
  right: 0;
}
.history-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.history-name span {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: $base-gray-color;
  margin-left: 20px;
}
.history-text {
  font-size: 15px;
  line-height: 20px;
  margin-left: 74px;
  flex-grow: 1;
  margin-top: -20px;
  width: 100%;
}

@include media-breakpoint-down(lg) {
  .history-chat {
    margin-bottom: 30px;
  }
  .history-name {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .history-name span {
    font-size: 13px;
    line-height: 16px;
    margin: 5px 0 0 0;
    width: 100%;
  }
  .history-chat-img {
    margin-right: 15px;
  }
  .history-text {
    margin: 15px 0 0 0;
  }
  .base-record-card {
    width: 100% !important;
  }
  .sort-element {
    margin-top: 30px;
    justify-content: flex-start;
  }
  .base-card-count {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .base-card-exp {
    margin-top: 15px;
    font-size: 15px;
    line-height: 20px;
  }
  .base-record-card .record-card-name {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    height: 40px;
  }
  .base-record-card img {
    width: 80px !important;
    height: 80px !important;
  }
  .admin-stat-card .record-card-name {
    height: 44px;
  }
}