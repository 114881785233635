.register-title {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  margin-top: 97px;
  margin-bottom: 47px;
}

.register-text {
  font-size: 15px;
  line-height: 20px;
}

.phone-country {
  margin-top: 35px;
}
.register-terms {
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;
}

#phone-input {
  padding: 0 22px 0 65px;
  width: 100%;
}

.phone-country .iti {
  width: 100%;
  margin-bottom: 50px;
}

.phone-country .iti__selected-flag {
  //width: 65px;
  justify-content: center;
}
.register-text-code span {
  color: $danger-color;
  font-weight: 700;
}

.phone-code {
  margin-top: 30px;
}

.phone-code input::-webkit-outer-spin-button,
.phone-code input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.phone-code input {
  text-align: center;
}
.repeat-code {
  font-size: 15px;
  line-height: 20px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.register-title-final {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  margin-top: 37px;
  margin-bottom: 47px;
}
.register-section-title {
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 30px;
}
.register-section {
  margin-bottom: 40px;
}

@include media-breakpoint-down(lg) {

  .register-section {
    margin-bottom: 30px;
  }
  .register-section-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .register-title-final {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .phone-code {
    margin-top: 50px;
  }
  .register-text-code span {
    display: block;
    margin-top: 10px;
  }
  .register-terms {
    margin-top: 30px;
  }
  .register-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .register-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
  }
}