.first-block {
  padding: 90px 0;
}

.first-block-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.first-block-subtitle {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
}

.first-block-data h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  margin-top: 20px;
  margin-bottom: 70px;
}

.second-block {
  padding: 40px 0 0;
  margin-bottom: 150px;
}

.second-block p {
  margin-bottom: 0;
  margin-top: 25px;
}

.second-block-pt-img {
  width: 71px;
  height: 71px;
  margin-right: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
                  0deg, rgba(105, 123, 214, 0.05), rgba(105, 123, 214, 0.05)), #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgb(112 127 209 / 8%), -10px 16px 24px rgb(112 127 209 / 8%);
  border-radius: 10px;
}

.second-block-pt {
  display: flex;
  align-items: center;
  margin: 35px 0;
}

.second-block-pt span {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.index-faq-block {
  margin-bottom: 130px;
}

.faq-block-item {
  background: rgba(253, 105, 75, 0.15);
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  padding: 30px;
  height: 100%;
  position: relative;
}

.faq-block-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 20px;
}

.faq-block-item-white {
  background-color: #fff;
  padding-bottom: 140px;
}
.faq-block-item-white a {
  color: #FD694B;
  font-weight: 700;
}
.faq-block-item-white a:hover {
  text-decoration: underline;
}
a.faq-block-item-arrow {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.index-socials-block {
  margin-bottom: 100px;
}
.index-socials-title {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
}
.index-socials {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.index-consult-block {
  margin-bottom: 150px;
}

.consult-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgb(112 127 209 / 8%), -10px 16px 24px rgb(112 127 209 / 8%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px;
}

.consult-card img {
  margin-right: 55px;
  flex-shrink: 0;
  border-radius: 50%;
  width: 104px;
  height: 104px;
}

.consult-card-body {
  width: 100%;
}

.consult-card-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 10px;
}

.consult-card-position {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 30px;
}
.consult-card .btn-wake-up {
  padding-top: 8px;
  padding-bottom: 8px;
}

.index-help-block {
  margin-bottom: 150px;
}

.index-help-container {
  border: 2px dashed $base-link-color;
  border-radius: 10px;
  padding: 100px 95px;
  position: relative;
}
.index-help-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.index-help-container ul li {
  margin: 20px 0;
  position: relative;
  padding-left: 15px;
}

.index-help-container ul li:after {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: $danger-color;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.index-help-pt {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgb(112 127 209 / 8%), -10px 16px 24px rgb(112 127 209 / 8%);
  border-radius: 28px;
  display: inline-block;
  padding: 15px 25px;
  position: absolute;
}
.index-help-pt1 {
  top: -15px;
  right: -29px;
}
.index-help-pt2 {
  top: 89px;
  right: 243px;
}
.index-help-pt3 {
  top: 162px;
  right: 25px;
}
.index-help-pt4 {
  top: 258px;
  right: 218px;
}
.index-help-pt5 {
  top: 302px;
  right: -53px;
}
.index-help-pt6 {
  top: 368px;
  right: 275px;
}
.index-help-pt7 {
  top: 442px;
  right: 36px;
}
.index-help-pt8 {
  right: 318px;
  bottom: -19px;
}

.index-reviews-block {
  margin-bottom: 150px;
}

.swiper-reviews-card {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 24px;
}
.swiper-reviews-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.swiper-reviews-info img {
  margin-right: 20px;
  flex-shrink: 0;
  border-radius: 50%;
}
.swiper-reviews-data {
  color: $base-gray-color;
  width: 100%;
}
.swiper-reviews-data span {
  display: block;
  font-size: 17px;
  line-height: 24px;
  color: #313131;
  margin-bottom: 5px;
}
.swiper-reviews-date {
  color: $base-gray-color;
  font-size: 15px;
  line-height: 20px;
}
.swiper-reviews-meta {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.swiper-navs {
  padding-bottom: 50px;
}

.swiper-next {
  position: absolute;
  right: 3px;
  bottom: 0;
  cursor: pointer;
  z-index: 10;
}
.swiper-prev {
  position: absolute;
  right: 72px;
  bottom: 0;
  cursor: pointer;
  z-index: 10;
}
.swiper-prev.swiper-button-disabled, .swiper-next.swiper-button-disabled {
  opacity: 0.5;
}

.index-how-block {
  margin-bottom: 150px;
}

.swiper-how .swiper-slide {
  text-align: center;
}

.swiper-how .swiper-slide img {
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08);
  filter: drop-shadow(-10px 16px 24px rgba(112, 127, 209, 0.08));
}

.index-how-tabs,
.quest-tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid $base-link-color;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}
.index-how-tabs li,
.quest-tabs li {
  padding: 15px 10px;
  width: 25%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.quest-tabs li {
  cursor: default !important;
}
.index-how-tabs li:hover {
  color: $base-link-color;
}
.index-how-tabs li.active,
.quest-tabs li.active {
  font-weight: 600;
  color: $base-link-color;
}
.index-how-tabs li.active span,
.quest-tabs li.active span {
  height: 5px;
  background-color: $base-link-color;
  width: 100%;
  left: 0;
  bottom: -3px;
  position: absolute;
  border-radius: 50px;
}
.index-how-tabs-container,
.quest-tabs-container {
  padding-bottom: 3px;
  overflow: auto;
  margin-bottom: 57px;
}
.index-reviews-block h2 {
  margin-bottom: 37px;
}
.swiper-index-reviews {
  //height: 615px;
}
.swiper-index-reviews .swiper-slide {
  //height: auto;
}
.swiper-index-reviews .swiper-slide .swiper-reviews-card {
  margin-bottom: 1.5rem;
  height: 300px;
}
.swiper-index-reviews .swiper-slide .swiper-reviews-card:last-child {
  margin-bottom: 0;
}
.swiper-index-reviews .swiper-reviews-text {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}
.add-reviews-link {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  color: $danger-color
}
.add-reviews-link:hover {
  color: $danger-color
}
.add-reviews-link:active {
  color: $danger-focus-color
}

.form-control-area {
  background: #FFFFFF;
  padding: 15px;
  border: 1px solid #D9DCEB;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
}


@include media-breakpoint-down(lg) {
  .swiper-index-reviews .swiper-reviews-text {
    -webkit-line-clamp: inherit;
  }
  .swiper-index-reviews .swiper-slide .swiper-reviews-card {
    height: auto;
  }
  .swiper-reviews-date {
    margin-top: 15px;
    font-size: 13px;
    line-height: 16px;
  }
  .swiper-reviews-data span {
    font-weight: bold;
  }
  .swiper-reviews-info {
    flex-direction: column;
    align-items: flex-start;
  }
  .add-reviews-link {
    justify-content: center;
  }
  .swiper-index-reviews {
    //height: auto;
  }
  .second-block, .index-how-block, .index-reviews-block, .index-help-block, .index-consult-block {
    margin-bottom: 100px;
  }
  .index-socials-block {
    margin-bottom: 30px;
  }
  .index-faq-block {
    margin-bottom: 80px;
  }
  .index-socials a img {
    width: 50px;
    height: 50px;
  }
  .index-socials {
    justify-content: space-between;
  }
  .index-socials-title {
    font-size: 21px;
    line-height: 26px;
  }
  .consult-card-title {
    font-size: 15px;
    line-height: 22px;
  }
  .consult-card img {
    margin-right: 0;
    margin-bottom: 35px;
    width: 80px;
    height: 80px;
  }
  .consult-card {
    margin-top: 30px;
    flex-direction: column;
    text-align: center;
    padding: 30px 15px;
  }
  a.faq-block-item-arrow {
    bottom: 15px;
    right: 15px;
  }
  .faq-block-item-arrow img {
    width: 50px;
  }
  .faq-block-item-white {
    padding-bottom: 50px !important;
  }
  .faq-block-item {
    font-size: 15px;
    line-height: 20px;
    padding: 15px;
  }
  .index-help-container ul li:last-child {
    margin-bottom: 0;
  }
  .index-help-container ul li {
    margin: 15px 0;
    line-height: 20px;
    font-size: 15px;
  }
  .index-help-container {
    padding: 20px 15px;
  }
  .swiper-reviews-card {
    font-size: 15px;
    line-height: 20px;
    padding: 15px;
  }
  .swiper-reviews-data {
    font-size: 15px;
  }
  .swiper-reviews-info img {
    width: 55px;
    height: 55px;
    margin-right: 15px;
  }
  .swiper-index-reviews .swiper-slide {
    width: 240px;
  }
  .swiper-index-reviews .swiper-wrapper {
    padding-bottom: 0;
  }
  .index-how-tabs-container,
  .quest-tabs-container {
    margin-bottom: 20px;
  }
  .swiper-prev {
    left: 3px;
    right: auto;
  }
  .index-how-tabs li {
    padding: 15px;
    width: 230px;
    flex-shrink: 0;
    font-size: 15px;
    line-height: 20px;
  }
  .quest-tabs li {
    padding: 15px;
    width: 165px;
    flex-shrink: 0;
    font-size: 15px;
    line-height: 20px;
  }
  .second-block-pt {
    margin: 15px 0px;
  }
  .second-block-pt-img {
    height: 56px;
    width: 56px;
    margin-right: 15px;
  }
  .second-block-pt span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .first-block {
    padding: 60px 0;
  }
  .first-block-content {
    display: block;
  }
  .first-block-subtitle {
    font-size: 17px;
    line-height: 24px;
  }
  .first-block-data h2 {
    font-size: 27px;
    line-height: 41px;
    margin-bottom: 38px;
  }
  .first-block-content img {
    margin: 0 auto;
    display: block;
  }
}
