.page-title-block {
  display: flex;
  align-items: center;
  margin-bottom: 37px;
}
.page-title-block .page-title {
  margin-bottom: 0;
}
.page-edit {
  margin-left: 67px;
  display: flex;
  align-items: center;
}
.page-edit svg {
  margin-right: 10px;
}
.page-edit {
  color: $danger-color;
}
.page-edit:focus {
  color: $danger-focus-color;
}
.page-edit:focus svg path {
  fill: $danger-focus-color;
}
.page-title-right {
  text-align: right;
  flex-grow: 1;
}
.page-title-block .btn-wake-up-record {
  margin: 0;
  width: auto;
  color: $base-font-color;
  padding-left: 15px;
  padding-right: 15px;
}
.profile-main-info-block {
  display: flex;
  align-items: start;
}
.profile-image {
  flex-shrink: 0;
  margin-right: 120px;
}
.profile-image {
  width: 261px;
  height: 261px;
  border-radius: 50%;
  overflow: hidden;
}
.profile-name {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
}

.profile-info-contacts {
  margin-top: 30px;
}
.profile-info-contacts span {
  display: block;
  font-size: 15px;
  line-height: 20px;
  color: $base-gray-color;
  margin-bottom: 5px;
}
.profile-info-price {
  margin-top: 30px;
}
.profile-info-price p {
  margin-bottom: 10px;
}
.profile-info-price p:last-child {
  margin-bottom: 0;
}
.profile-info-skills {
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.profile-info-skills span {
  flex-shrink: 0;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 15px;
  margin: 0 10px 10px 0;
  background: #FFFFFF;
  border: 1px solid #D9DCEB;
  box-sizing: border-box;
  border-radius: 34px;
}
.profile-content {
  margin-top: 69px;
}
.profile-info-section {
  margin-top: 30px;
}
.profile-info-title {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
  color: $base-link-color;
}
.profile-info-content {

}
.profile-info-content p {
  margin-bottom: 5px;
}
.profile-info-content p:last-child {
  margin-bottom: 0;
}
.profile-info-documents {
  display: flex;
  flex-wrap: wrap;
}
.profile-info-document {
  font-size: 15px;
  line-height: 20px;
  margin-right: 24px;
  width: 166px;
}
.profile-info-document a {
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.patient.profile-content {
  margin-top: 20px;
}
.patient .profile-info-skills {
  margin-top: 25px;
}
.patient .profile-info-price {
  margin-top: 20px;
  margin-bottom: 35px;
}

.profile-main-info {
  width: 100%;
}

@include media-breakpoint-down(lg) {
  .profile-main-info .btn-wake-up-record {
    width: auto;
    margin-top: 0;
  }
  .profile-info-documents {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .profile-info-document {
    flex-shrink: 0;
  }
  .profile-info-content p {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .profile-info-title {
    font-size: 13px;
    line-height: 16px;
  }
  .profile-info-skills {
    margin-top: 20px;
    justify-content: center;
  }
  .profile-info-skills span {
    font-size: 11px;
    line-height: 14px;
    padding: 5px 10px;
    margin: 0 5px 5px 0;
  }
  .profile-content {
    margin-top: 30px;
  }
  .page-edit {
    margin-left: 0;
    font-size: 15px;
    line-height: 20px;
  }
  .page-title-block {
    justify-content: space-between;
  }
  .profile-main-info-block {
    flex-direction: column;
    align-items: center;
  }
  .profile-image {
    margin: 0;
    width: 166px;
    height: 166px;
  }
  .profile-main-info {
    margin-top: 20px;
  }
  .profile-name {
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
  }
  .profile-info-contacts {
    margin-top: 20px;
    font-size: 15px;
    line-height: 20px;
  }
  .profile-info-price {
    margin-top: 20px;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  }
  .profile-info-price p {
    margin-bottom: 10px;
  }
}