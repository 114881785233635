.breadcrumbs-block {
  padding: 40px 0 30px;
  font-size: 14px;
  line-height: 24px;
}
.breadcrumbs-block ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumbs-block ul li a {
  color: $base-gray-color;
}
.breadcrumbs-block ul li a:after {
  content: '/';
  margin: 0 15px;
}

.article-item {
  background: #FFFFFF;
  border-radius: 5px;
  display: block;
  overflow: hidden;
}
.article-item-img {
  background: #D9DCEB;
  height: 160px;
}
.article-item-meta {
  margin: 10px;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
}
.article-item-meta li {
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: $base-font-color;
}
.article-item-meta li svg {
  margin-right: 10px;
}
.article-item-meta li svg path {
  fill: $base-link-color;
}
.article-item-meta li:last-child {
  margin-right: 0;
}
.article-item-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  margin: 10px;
  height: 50px;
  color: $base-font-color;
}
.article-item:hover .article-item-title {
  color: $base-link-color;
}
.article-item-big {
  background: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  //height: 260px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  min-height: 160px;
}
.article-item-big .article-item-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 86px;
  flex-wrap: wrap;
  margin: 0;
  align-items: flex-end;
  padding: 0 20px 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.article-item-big .article-item-meta li {
  color: #fff;
}
.article-item-big  .article-item-big-title {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #fff;
  width: 100%;
}
.article-item-big .article-item-meta a:hover {
  color: #fff;
}

.article-item-big .article-item-meta svg path {
  fill: #fff;
}
.recent-articles .recent-article:last-child {
  margin-bottom: 0;
}
.recent-article {
  margin-bottom: 30px;
}
.recent-article a {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: $base-font-color;
}
.recent-article ul {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
}

.recent-article ul li {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.recent-article ul li svg {
  margin-right: 10px;
}
.recent-article ul li svg path {
  fill: $base-link-color;
}
.recent-article ul li:last-child {
  margin-right: 0;
}

.article-content p {
  margin-bottom: 20px;
}
.article-content h3 {
  font-family: Inter;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 20px;
}
.article-content img:first-child {
  margin-top: 0 !important;
}
.article-content img {
  margin: 20px 0 40px;
}
.article-title {
  font-family: Inter;
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 20px;
}
.article-meta {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
}
.article-meta svg {
  margin: 0 10px 0 20px;
}
.article-request {
  border-left: 5px solid $danger-color;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: start;
  margin-bottom: 30px;
}
.article-request span {
  font-weight: 600;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 10px;
}
.article-request .btn {
  margin-top: 30px;
}
.article-subjects {
  background: #FFFFFF;
  border-radius: 5px;
  padding: 30px 50px;
  margin: 90px 0 50px 0;
}

.article-subjects span {
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}
.article-subjects ol {
  margin: 0 !important;
  padding-left: 20px !important;
}
.article-subjects ol li {
  margin-bottom: 10px;

}
.article-subjects ol li:last-child {
  margin-bottom: 0px;
}
.article-subjects ol li a {
  color: $base-font-color;
  font-weight: 400;
}
.article-subjects ol li a:hover {
  color: $base-link-focus-color;
}

.article-content h2 {
  font-weight: 600;
  font-size: 23px;
  line-height: 29px;
  margin: 50px 0 20px;
}
.article-content ol, .article-content ul {
  margin-bottom: 20px;
  padding-left: 20px;
}
.article-content ol li, .article-content ul li {
  margin-bottom: 5px;
}
.article-content ol li:last-child, .article-content ul li:last-child {
  margin-bottom: 0px;
}
.our-therapists .page-title-block {
  justify-content: space-between;
}
.our-therapists {
  margin-top: 90px;
  margin-bottom: 60px;
}
.ya-share2 + .article-request {
  margin-top: 90px;
}

.order-actions-item {
  margin: 25px 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.order-actions-item span {
  background: #FFFFFF;
  width: 71px;
  height: 71px;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
  border-radius: 10px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

@include media-breakpoint-down(lg) {
  .order-actions-item span {
    width: 56px;
    height: 56px;
    margin-right: 15px;
  }
  .order-actions-item {
    margin: 15px 0;
    font-size: 15px;
    line-height: 20px;
  }
  .our-therapists {
    margin-bottom: 20px;
  }
  .ya-share2+.article-request {
    margin-top: 50px;
  }
  .article-content .h2, .article-content h2 {
    font-weight: 500;
    font-size: 21px;
    line-height: 29px;
    margin: 40px 0 20px;
  }
  .article-subjects {
    margin: 50px 0 30px;
    padding: 20px;
  }
  .article-subjects ol, .article-subjects ul {
    font-size: 15px;
    line-height: 20px;
  }
  .article-request span {
    font-size: 18px;
    line-height: 25px;
  }
  .article-request {
    margin-top: 20px;
    padding-left: 15px;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .article-request .btn {
    margin-top: 20px;
  }
  .article-meta span {
    width: 100%;
    margin-bottom: 15px;
    color: $base-font-color;
  }
  .article-meta {
    flex-wrap: wrap;
    color: $base-gray-color;
  }
  .article-meta svg:nth-child(2) {
    margin-left: 0;
  }
  .recent-articles-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .recent-articles {
    margin-top: 50px;
  }
  .recent-article {
    margin-bottom: 20px;
  }
  .article-content img {
    margin: 10px 0 30px;
  }
  .article-title {
    font-weight: 600;
    font-size: 23px;
    line-height: 33px;
  }

  .article-item {
    max-width: 306px;
    margin: 0 auto;
  }
  .article-item-big {
    max-width: 306px;
    margin: 0 auto;
  }

  .article-item-big  .article-item-big-title {
    margin-top: 10px;
  }
}