.profile-edit-img {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 40px;
}

.profile-edit-img img,
.profile-edit-img canvas {
  width: 166px;
  height: 166px;
  display: block;
  margin: 0 auto 20px;
  border-radius: 50%;
}

//#avatar-croppie {
//  width: 166px;
//  //height: 166px;
//  overflow: hidden;
//  margin: 0 auto 20px;
//  //border-radius: 50%;
//}
#avatar-crop-popup {
  max-width: 600px;
  padding: 30px;
}

.cropper-bg {
  //max-width: 400px;
  //max-height: 400px;
}

#avatar-croppie .cr-boundary {
  border-radius: 50%;
  overflow: hidden;
}

.profile-edit-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-edit-avatar a {
  font-size: 15px;
  line-height: 20px;
  color: $base-font-color;
}

.croppie-container .cr-viewport, .croppie-container .cr-resizer {
  border: 0 !important;
  box-shadow: none !important;
}

.profile-edit-avatar svg {
  margin-right: 10px;
}

.profile-edit-avatar:hover {
  color: $base-font-color;
}

.profile-edit-avatar a:hover {
  color: $base-font-color;
}

.form-field-group {
  margin-bottom: 30px;
}

.form-field-group label {
  font-size: 15px;
  line-height: 20px;
  color: $base-gray-color;
  display: block;
  margin-bottom: 10px;
}

.select2 .select2-selection {
  border: 1px solid #d9dceb;
  background: #fff;
  height: 50px;
  padding: 0 15px;
  border-radius: 5px;
  line-height: 50px;
}

.select2 .select2-selection .select2-selection__rendered {
  height: 48px;
  line-height: 48px;
  padding: 0 15px 0 0;
  color: $base-font-color;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {

}

.select2-container--default .select2-selection--single .select2-selection__arrow:after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACLSURBVHgBdZCxDYQwDEXxZQHKKBUjRKmuzE12dx0lmzACsECUkpIuKdkgfEuAwBKWHEXf79uWyVrriahHdiGEfyUC9Qa1Ad/1hafmLKX8nHPfB7BhRuWcZ601NPIQvDGmSilNAlzQ7KO4AwyjMPC09grGGBd1jBSG977eCTKjrjsKww18DL4Qspb6BibtQOpA6rT5AAAAAElFTkSuQmCC');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s;
}

.select2-container--open.select2-container--default .select2-selection--single .select2-selection__arrow:after {
  transform: rotate(180deg) translateX(50%) translateY(50%);
}

.select2-dropdown {
  border: 1px solid #d9dceb;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
  top: 0;
  right: 0;
  width: 30px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #d9dceb;
  border-radius: 3px;
}

select + .select2 {
  width: 100% !important;
}

.profile-edit-section {
  margin: 10px 0 30px 0;
}

.profile-edit-title {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;
  color: $base-gray-color;
}

.profile-skills-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.profile-skills-tag-item {
  flex-shrink: 0;
  background: #FFFFFF;
  border: 1px solid $base-link-color;
  border-radius: 34px;
  padding: 5px 13px 5px 15px;
  margin: 0 10px 10px 0;
  display: flex;
  align-items: center;
}

.profile-skills-tag-item svg {
  margin-left: 10px;
  cursor: pointer;
}

.btn-add-skills {
  background: #FFFFFF;
  border: 1px solid #D9DCEB;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-top: 10px;
}

.btn-add-skills svg {
  margin-right: 10px;
}

.btn-add-skills:hover {
  color: $base-link-focus-color;
  border-color: $base-link-focus-color;
}

.btn-add-skills:hover svg path {
  fill: $base-link-focus-color;
}

.btn-add-skills:active {
  color: $base-link-active-color;
  border-color: $base-link-active-color;
}

.btn-add-skills:active svg path {
  fill: $base-link-active-color;
}

.profile-skills-add-item .btn-wake-up {
  padding-top: 0;
  padding-bottom: 0;
  height: 44px;
}

.profile-skills-add-item .form-control {
  height: 44px;
}

.profile-skills-add-item {
  margin-top: 10px;
  padding-bottom: 10px;
}

.profile-edit-section textarea {
  background: #FFFFFF;
  padding: 15px;
  border: 1px solid #D9DCEB;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  resize: none;
  overflow: hidden;
}

.profile-info-document-add {
  background: #FFFFFF;
  border: 1px solid #D9DCEB;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
  line-height: 22px;
  width: 166px;
  color: $danger-color;
  height: 233px;
  cursor: pointer;
  flex-shrink: 0;
}

.profile-info-document-add svg {
  margin-bottom: 10px;
}

#add-new-document {
  padding: 50px;
  border-radius: 5px;
  max-width: 545px;
}

#add-new-document.fancybox__content > .carousel__button.is-close {
  top: 10px;
  right: 10px;
  box-shadow: none;
  color: $base-link-color;
}

#add-new-document.fancybox__content > .carousel__button.is-close svg {
  width: 15px;
}

.document-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
}

.document-subtitle {
  margin-top: 20px;
}

.document-file {
  margin-top: 40px;
}

.document-file img {
  display: none;
}

.document-change {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.document-file span {
  font-size: 15px;
  line-height: 20px;
  display: block;
  margin-top: 15px;
  color: #5A5A5A;
}

.document-change:focus {
  outline: none;
}

#add-new-document .form-field-group {
  margin-top: 40px;
  margin-bottom: 0;
}

#document-previews {
  max-width: 166px;
  margin-bottom: 20px;
}

.document-submit {
  display: none;
}

.profile-info-document-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(49, 49, 49, 0.3);
  opacity: 0;
  transition: all 0.3s;
}

.profile-info-document a:hover .profile-info-document-overlay {
  opacity: 1;
}

.profile-info-document .edit-document {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.profile-info-document .remove-document {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.profile-info-document-hover {
  position: relative;
}

.profile-info-document-btns {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 40px;
  opacity: 0;
  transition: all 0.3s;
}

.profile-info-document-hover:hover .profile-info-document-btns {
  opacity: 1;
  z-index: 100;
}

.profile-info-document-btns:hover + a .profile-info-document-overlay {
  opacity: 1;
}

@include media-breakpoint-down(lg) {
  #add-new-document {
    padding: 40px 20px 20px;
  }
  .profile-info-document-add {
    order: -1;
    margin-right: 24px;
  }
  .profile-edit-img {
    margin-top: 40px;
  }
  .form-field-group {
    margin-bottom: 20px;
  }
  .profile-skills-tag-item {
    font-size: 11px;
    line-height: 14px;
  }
  .btn-add-skills {
    font-size: 13px;
    line-height: 16px;
  }
}