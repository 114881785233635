.static-title {
  margin-top: 80px;
  margin-bottom: 50px;
}
.for-therapists-title {
  font-weight: 600;
  font-size: 23px;
  line-height: 29px;
  margin-bottom: 24px;
  color: $base-link-color;
}
.for-therapists-item {
  margin-bottom: 40px;
}

@include media-breakpoint-down(lg) {
  .static-title {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .for-therapists-title {
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .for-therapists-item {
    font-size: 15px;
    line-height: 20px;
  }
}