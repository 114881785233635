record-custom.therapist.record-card .record-card-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal;
  height: 52px;
}

.record-custom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 15px;
  line-height: 20px;
  width: 100%;
}

.record-card.new-record {
  height: 100%;
}
.record-card.new-record a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $danger-color;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}
.record-card.new-record a svg {
  margin-bottom: 25px;
}

.mob-new-record {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 25px;
}
.mob-new-record a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $danger-color;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}
.mob-new-record a svg {
  margin-right: 16px;
}

.patient-title {
  font-weight: bold;
  font-size: 50px;
  line-height: 44px;
  margin-top: 20px;
  color: $base-link-color;
}

.patient-subtitle {
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  margin-top: 50px;
}
.patient-start-section {
  margin-top: 100px;
}
.patient-section-title {
  font-weight: 600;
  font-size: 27px;
  line-height: 40px;
  margin-bottom: 24px;
}
.patient-section-block p {
  margin-bottom: 18px;
}
.patient-section-block p:last-child {
  margin-bottom: 0px;
}
.service-links {
  margin: 0;
  padding: 0;
  list-style: none;
}
.service-links li {
  margin-bottom: 10px;
}
.service-links li:last-child {
  margin-bottom: 0px;
}
.service-links li a {
  font-size: 15px;
  line-height: 20px;
  color: $danger-color;
  border-bottom: 1px dashed $danger-color;
}
.service-links li a:hover {
  border-bottom-color: transparent;
}
.patient-start-section:last-child {
  margin-bottom: 50px;
}
.therapist-single {
  background: #FFFFFF;
  padding: 40px 30px;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.therapist-single img {
  flex-shrink: 0;
  width: 104px;
  height: 104px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 55px;
}
.therapist-single-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-bottom: 10px;
}
.therapist-single-position {
  margin-bottom: 30px;
  line-height: 20px;
  font-size: 15px;
}
.patient-profile-img img,
.patient-profile-img canvas {
  width: 166px;
  height: 166px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 22px;
}
.patient-profile-img {
  width: 166px;
  text-align: center;
}

.patient-profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.patient-profile-avatar a {
  font-size: 15px;
  line-height: 20px;
  color: $base-font-color;
}
.patient-profile-avatar svg {
  margin-right: 10px;
}
.patient-profile-avatar:hover {
  color: $base-font-color;
}
.patient-profile-avatar a:hover {
  color: $base-font-color;
}
.patient-profile-img .btn-wake-up-record {
  color: $base-font-color;
  margin-top: 50px;
}
.patient-stat {
  background: #FFFFFF;
  border-radius: 10px;
  width: 260px;
  margin-right: 24px;
  height: 130px;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.patient-stat span {
  font-weight: 500;
  font-size: 23px;
  line-height: 29px;
  display: block;
  margin-bottom: 10px;
}
.patient-profile-info .patient-stat:last-child {
  margin-right: 0;
}
.patient-profile-info {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}
.patient-stat-images {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.patient-stat-images img {
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -10px;
  position: relative;
}
.patient-stat-images img:last-child {
  margin: 0;
}
.patient-stat-images i {
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  font-style: normal;
  color: $danger-color;
  margin-left: 10px;
}

.service-item {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 30px 30px 40px;
  min-height: 228px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  transition: all 0.3s;
}
.service-item * {
  transition: all 0.3s;
}
.service-item-title {
  font-weight: bold;
}
.service-item-text {
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  color: $base-gray-color;
  flex-grow: 1;
}
.service-item-price {
  font-weight: bold;
}
.service-item:hover {
  cursor: pointer;
  //background-color: $base-link-color;
}
//.service-item:hover > * {
//  color: #fff;
//}


.service-item.active {
  background-color: $base-link-color;
}
.service-item.active > * {
  color: #fff;
}

.service-calendar {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
  border-radius: 10px;
  padding: 30px;
}

.service-calendar-title {
  font-weight: 500;
}
.service-calendar-dates {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-calendar-times {
  margin-top: 30px;
  display: none;
}
.calendar-times {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.btn-wake-up-calendar {
  margin-top: 30px;
}
.service-calendar-control {
  width: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.service-calendar-dates-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.service-calendar-control button {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  padding: 3px;
}
.service-calendar-control button:first-child {
}
.service-calendar-control button:active svg path {
  stroke: $danger-focus-color;
}
.service-calendar-date {
  font-size: 15px;
  line-height: 20px;
  color: $base-gray-color;
  text-align: center;
}
.service-calendar-date.busy {
  opacity: 0.5;
  cursor: not-allowed;
}
.service-calendar-date.busy span {
  cursor: not-allowed;
}
.service-calendar-date.busy span:hover {
  color: inherit;
  background-color: transparent;
}
.service-calendar-date span {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin: 10px auto 0;
  opacity: 0;
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-calendar-date span:hover {
  background-color: $danger-color;
  color: #fff;
}
.service-calendar-date.active span {
  background-color: $danger-color;
  color: #fff;
}
.calendar-times button {
  border: 1px solid $base-gray-color;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  height: 40px;
  font-size: 15px;
  line-height: 20px;
  padding: 0 15px;
  margin-right: 20px;
  color: $base-gray-color;
  transition: all 0.3s;
  margin-bottom: 5px;
}
.calendar-times button:hover {
  border-color: $danger-color;
  color: $danger-color;
}
.calendar-times button.active {
  border-color: $danger-color;
  color: #fff;
  background-color: $danger-color;
}
.btn-wake-up-calendar {
  background: $danger-color;
  border-radius: 5px;
  border: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #fff;
  padding: 10px 37px;
}
.service-calendar-apply {
  //display: none;
}
.btn-wake-up-calendar:hover {
  box-shadow: 0px 10px 33px rgba(253, 105, 75, 0.25);
  color: #fff;
}
.btn-wake-up-calendar:active {
  background-color: $danger-focus-color;
  color: #fff;
}
.service-calendar {
  display: none;
}
.service-calendar.active {
  display: block;
}
.calendar-absolute .service-calendar {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

.quest-title {
  font-weight: bold;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
}
.quest-subtitle {
  margin-top: 20px;
  text-align: center;
}
.quest-body {
  margin-top: 70px;
}
.quest-body-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
}
.quest-checkboxes {
  margin-top: 40px;
}
.quest-checkboxes-title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 30px;
}
label.checkbox-item input {
  display: none;
}
label.checkbox-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 30px;
}
label.checkbox-item span {
  margin-right: 14px;
  border: 2px solid $base-link-color;
  width: 21px;
  height: 21px;
  border-radius: 3px;
  position: relative;
}
label.checkbox-item input:checked + span:before {
  content: '';
  position: absolute;
  height: 2px;
  background-color: $base-link-color;
  width: 7px;
  transform: rotate(45deg);
  top: 9px;
  left: 2px;
}
label.checkbox-item input:checked + span:after {
  content: '';
  position: absolute;
  height: 2px;
  background-color: $base-link-color;
  width: 10px;
  transform: rotate(-45deg);
  top: 7px;
  left: 6px;
}
.quest-checkboxes-hide {
  display: none;
}
.quest-checkboxes-more {
  display: flex;
  align-items: center;
  color: $base-gray-color;
}
.quest-checkboxes-more span {
  margin-right: 15px;
}
.quest-checkboxes-more.open svg {
  transform: rotate(180deg);
}
.quest-checkboxes-more:hover svg path {
  fill: $base-link-focus-color;
}

.quest-group-btns {
  margin-top: 50px;
}
.quest-group-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 40px;
}

.btn-group .btn-checkbox {
  background: #FFFFFF;
  border: 1px solid #C8CCE2;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 17px;
  line-height: 24px;
  color: $base-font-color;
  padding: 12px 20px;
  white-space: nowrap;
}
.btn-group input:checked + label {
  color: #fff;
  background-color: $base-link-color;
}
.btn-group label {
  box-shadow: none !important;
}
.btn-group label:hover {
  background-color: #C8CCE2;
}
.next-step-row {
  margin-top: 100px;
  margin-bottom: 50px;
}
.quest-step {
  display: none;
}
.quest-step.active {
  display: block;
}
.quest-label {
  font-size: 21px;
  line-height: 26px;
}
.quest-row {
  margin-top: 40px;
}
.quest-subtitle a {
  color: $danger-color;
}
.quest-subtitle a:hover {
  color: $danger-focus-color;
}

.quest-therapist-card {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
  border-radius: 10px;
  margin-top: 30px;
}
.quest-therapist-info {
  display: flex;
}
.quest-therapist-photo {
  width: 106px;
  text-align: center;
  color: $base-gray-color;
  font-size: 17px;
  line-height: 26px;
  margin-right: 54px;
}
.quest-therapist-photo img {
  width: 106px;
  height: 106px;
  border-radius: 50%;
  margin-bottom: 30px;
}
.quest-therapist-photo span {
  font-weight: 600;
  display: block;
  color: $base-font-color;
}
.quest-therapist-name {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}
.quest-therapist-skills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  font-size: 13px;
  line-height: 16px;
  color: $base-link-color;
}
.quest-therapist-skills span {
  border: 1px solid $base-gray-color;
  padding: 5px 15px;
  margin: 0 10px 10px 0;
  border-radius: 50px;
}

.quest-therapist-data p {
  margin-bottom: 0;
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
}
.quest-therapist-data p span {
  font-weight: 600;
}

.open-therapist-data {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $base-gray-color;
  margin-top: 30px;
  font-size: 15px;
  line-height: 20px;
}
.open-therapist-data:hover {
  color: $base-gray-color;
}
.open-therapist-data svg {
  margin-left: 12px;
  transition: all 0.3s;
}
.quest-therapist-hidden {
  display: none;
}
.open-therapist-data.open svg {
  transform: rotate(180deg);
}
.quest-therapist-hidden .profile-info-title {
  color: $base-gray-color;
}
.quest-therapist-data {
  margin-bottom: 40px;
}
.quest-pay-content {
  font-size: 17px;
  line-height: 35px;
  margin-top: 60px;
}
.pay-therapist-card {
  margin-top: 50px;
  display: flex;
  align-items: center;
  padding: 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px -4px rgba(112, 127, 209, 0.08), -10px 16px 24px rgba(112, 127, 209, 0.08);
}
.pay-therapist-card img {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  margin-right: 24px;
  flex-shrink: 0;
}
.pay-therapist-info {
  font-size: 18px;
  line-height: 24px;
  flex-grow: 1;
}
.pay-therapist-info span {
  font-weight: bold;
}
.pay-therapist-info .row {
  margin-top: 24px;
}
.pay-therapist-info .row:first-child {
  margin-top: 0;
}
.patient-new-request {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.record-card-timer-text {
  margin-top: 20px;
  font-size: 14px;
  line-height: 26px;
}
.record-card-timer {
  margin-top: 5px;
  font-size: 14px;
  line-height: 16px;
  color: $base-gray-color;
  display: flex;
  align-items: center;
  justify-content: center;
}
.record-card-timer .timer-section {
  margin: 0 5px;
}
.record-card-timer .timer-section span:first-child {
  color: $danger-color;
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
}
.timer-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@include media-breakpoint-down(lg) {
  .patient-new-request {
    flex-direction: column;
    align-items: start;
  }
  .patient-new-request a {
    margin-top: 20px;
  }
  .pay-therapist-info .row div:first-child {
    margin-bottom: 5px;
  }
  .pay-therapist-info .row {
    margin-top: 20px;
  }
  .pay-therapist-info span {
    font-size: 16px;
    line-height: 24px;
  }
  .pay-therapist-info {
    margin-top: 30px;
    font-size: 15px;
    line-height: 20px;
  }
  .pay-therapist-card img {
    width: 80px;
    height: 80px;
    margin-right: 0px;
  }
  .pay-therapist-card {
    margin-top: 40px;
    padding: 30px 15px;
    flex-direction: column;
  }
  .quest-pay-content {
    font-size: 16px;
    line-height: 24px;
  }
  .quest-therapist-skills {
    font-size: 11px;
    line-height: 14px;
    margin-top: 20px;
  }
  .quest-therapist-skills span {
    margin: 0 5px 5px 0;
    padding: 5px 10px;
  }
  .quest-therapist-name {
    font-size: 15px;
    line-height: 20px;
    margin-top: 20px;
  }
  .quest-therapist-photo img {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 0;
  }
  .quest-therapist-info {
    flex-direction: column;
  }
  .quest-therapist-photo {
    width: auto;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: left;
  }
  .quest-row {
    margin-top: 20px;
  }
  .quest-label {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .btn-group-overflow {
    overflow: auto;
    padding-left: 1px ;
  }
  .btn-group .btn-checkbox {
    font-size: 15px;
    line-height: 20px;
  }
  .next-step-row {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .quest-group-title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 30px;
  }
  .quest-group-btns {
    margin-top: 25px;
  }
  label.checkbox-item {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 24px;
  }
  label.checkbox-item span {
    margin-right: 10px;
  }
  .quest-checkboxes-title {
    font-size: 15px;
    line-height: 20px;
  }
  .quest-checkboxes {
    margin-top: 30px;
  }
  .quest-body-title {
    font-size: 16px;
    line-height: 24px;
  }
  .quest-body {
    margin-top: 40px;
  }
  .quest-subtitle {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .quest-title {
    font-size: 17px;
    line-height: 24px;
    margin-top: 40px;
  }
  .service-item-price {
    margin-top: 20px;
  }
  .service-item {
    padding: 20px 15px;
    margin-bottom: 15px;
  }
  .service-calendar-date {
    font-size: 13px;
    line-height: 16px;
  }
  .service-calendar-title {
    font-size: 15px;
    line-height: 20px;
  }
  .service-calendar {
    padding: 20px 15px;
    margin-bottom: 15px;
  }

  .patient-profile-content form .btn-wake-up {
    width: 100%;
  }
  .patient-stat {
    width: 100%;
    height: 110px;
  }
  .patient-profile-info {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .patient-profile-img .btn-wake-up-record {
    margin-top: 20px;
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .patient-profile-img {
    width: 100%;
  }
  .therapist-single-name {
    font-size: 15px;
    line-height: 22px;
  }
  .therapist-single img {
    width: 80px;
    height: 80px;
    margin-right: 0px;
    margin-bottom: 35px;
  }
  .therapist-single {
    text-align: center;
    flex-direction: column;
    margin-top: 30px;
    padding: 26px 15px 30px;
  }
  .patient-section-title {
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .patient-start-section {
    margin-top: 50px;
  }
  .patient-subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }
  .patient-title {
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    margin-top: 30px;
  }
  .therapist.record-card .record-card-name {
    height: 46px;
  }
}